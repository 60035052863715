<template>
    <div class="py-6 text-sm">
        <div class="grid grid-cols-12 gap-4 bg-gray-100 py-3 px-5 rounded-lg mb-2">
            <div class="col-span-6">
                Benämning
            </div>
            <div class="col-span-1">
                Antal
            </div>
            <div class="col-span-1 text-center">
                Enh
            </div>
            <div class="col-span-2 text-right">
                Å-pris
            </div>
            <div class="col-span-2 text-right">
                Summa
            </div>
        </div>
        <div class="bg-gray-100 py-4 px-5 rounded-lg content">
            <div v-for="service in invoiceData.calendar_services" :key="service.id" class="grid grid-cols-12 gap-4 mb-2">
                <div class="col-span-6">
                    {{ service.name }}
                </div>
                <div class="col-span-1">
                    {{ normalizeTime(service.duration) }}
                </div>
                <div class="col-span-1 text-center">
                    {{ $t('economy.' + service.price_type) }}
                </div>
                <div class="col-span-2 text-right">
                    {{ intToMoneyFormat(service.price) }}
                </div>
                <div class="col-span-2 text-right">
                    {{ intToMoneyFormat(service.final_price) }}
                </div>
            </div>
            <div v-for="service in invoiceData.permanent_items" :key="service.uuid" class="grid grid-cols-12 gap-4 mb-2">
                <div class="col-span-6 flex justify-between">
                    <p>
                        {{ service.name }}
                    </p>
                    <el-tooltip :enterable="false" :openDelay="500" effect="dark" :content="$t('common.delete')" placement="left">
                        <el-button class="btn-remove" type="danger" size="mini" :disabled="!userCan('delete invoices')" @click="handleDeleteService(service.uuid)">
                            <fa-icon :icon="['fas', 'trash']" />
                        </el-button>
                    </el-tooltip>
                </div>
                <div class="col-span-1 text-center">
                    {{ formatCount(service) }}
                </div>
                <div class="col-span-1 text-center">
                    {{ $t('economy.' + service.price_type) }}
                </div>
                <div class="col-span-2 text-right">
                    {{ intToMoneyFormat(service.price) }}
                </div>
                <div class="col-span-2 text-right">
                    {{ intToMoneyFormat(service.final_price) }}
                </div>
            </div>
        </div>
        <!-- Summary -->
        <div class="grid grid-cols-5 gap-2 mt-2">
            <div class="col-span-4 grid-cols-4 grid bg-gray-100 p-4 rounded-lg">
                <div class="text-right" :class="{ 'col-start-2': invoiceData.client_data.type === 'company' }">
                    <p>Exkl moms</p>
                    <p>{{ intToMoneyFormat(invoiceData.total_netto) }}</p>
                </div>
                <div class="text-right">
                    <p>Inkl moms</p>
                    <p>{{ intToMoneyFormat(invoiceData.total_brutto) }}</p>
                </div>
                <div class="text-right">
                    <p>Moms 25%</p>
                    <p>{{ intToMoneyFormat(invoiceData.total_tax) }}</p>
                </div>
                <div v-if="invoiceData.client_data.type === 'private'" class="text-right">
                    <p>Skattereduktion</p>
                    <p>{{ intToMoneyFormat(invoiceData.total_skattereduktion) }}</p>
                </div>
            </div>
            <div class="col-span-1 text-lg font-bold text-center bg-gray-100 p-2 rounded-lg">
                <p>ATT BETALA</p>
                <p>{{ intToMoneyFormat(invoiceData.total_final) }}</p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    // @Filip
    props: {
        invoiceData: {
            type:    Object,
            default: () => {},
        },
    },

    methods: {
        formatCount(item) {
            if (item.price_type === 'hourly') {
                return this.normalizeTime(item.count);
            }
            return item.count;
        },

        handleDeleteService(uuid) {
            this.$confirm(this.$t('common.are_you_sure'), this.$t('common.warning'), {
                confirmButtonText: this.$t('common.yes'),
                cancelButtonText:  this.$t('common.cancel'),
                type:              'warning',
                // @Filip
                beforeClose:       async (action, instance, done) => {
                    if (action !== 'confirm') return done();
                    // @Filip
                    instance.confirmButtonLoading = true;
                    instance.closeOnClickModal = false;

                    try {
                        await axios.delete(`${process.env.VUE_APP_CORE_API_URL}/economy/invoices/remove_item/${uuid}`);
                        this.$notify.success({ title: 'Success' });
                        this.$emit('refresh');
                    } catch {}

                    done();
                    instance.confirmButtonLoading = false;
                    instance.closeOnClickModal = true;
                },
            });
        },
    },
};
</script>
<style scoped>
.content {
    min-height: 200px;
}
.btn-remove {
    height: 25px !important;
    width: 25px !important;
    display: flex;
    padding: 0 !important;
    justify-content: center;
    align-items: center;
}
</style>
